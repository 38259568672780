import React from "react"
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAllSocialNetworks } from "../../hooks/use-all-social-networks";

const rsList = useAllSocialNetworks

const Index = props => {
  return rsList.map(rs => {
    return (
      <Link to={rs.url} title={rs.name}>
        <FontAwesomeIcon icon={rs.icon} />
      </Link>
    )
  })
}

Index.propTypes = {}

export default Index
